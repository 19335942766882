<template>
  <div>
    <b-navbar class="mk-navbar" toggleable="lg" type="light" fixed="top" variant="light">
      <b-navbar-toggle @click="handleClick" target="nav-collapse" id="navButton"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
         <b-nav-item href="/contact">اتصل بنا</b-nav-item>
         <b-nav-item href="/transactions">الصفقات</b-nav-item>
         <b-nav-item href="/home">الخريطة</b-nav-item>
         <b-nav-item href="/#section_features">الرئيسية</b-nav-item>
         <!-- <b-nav-item href="/real-estate-statistics">الإحصائيات العقارية</b-nav-item> -->
       </b-navbar-nav>
     </b-collapse>
     <b-navbar-brand href="/">
      <img :src="logo" :width="width" />
     </b-navbar-brand>
   </b-navbar>
 </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      windowTop: window.top.scrollY,
      logo: require("@/assets/logo2.svg"),
      width: "150px"
    }
  },
  components: {

  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY
      let nav = document.querySelector(".navbar")
      if (this.windowTop >= 100 && !nav.className.includes("navbar-fixed")) {
        nav.classList.add("navbar-fixed")
        nav.classList.remove("mk-navbar")
        this.width = "60px"
        this.logo = require("@/assets/logo.svg")
      }
      else if(this.windowTop < 100 && nav.className.includes("navbar-fixed")) {
        nav.classList.remove("navbar-fixed")
        nav.classList.add("mk-navbar")
        this.width = "150px"
        this.logo = require("@/assets/logo2.svg")
      }
    },
    handleClick() {
       let nav = document.querySelector(".navbar")
      let navToggler = document.querySelector(".navbar-toggler")
      if (navToggler.className.includes("not-collapsed")) {
        nav.classList.remove("mobile-bg")
      }
      else if(navToggler.className.includes("collapsed")) {
        nav.classList.add("mobile-bg")
     }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  }
}
</script>
