<template>
  <div>
  <footer>
    <b-container fluid>
      <b-row>
        <b-col md="9">
         <p>
          <a href="/privacy">{{ $t('privacy') }}</a>
          <a href="/contact">{{ $t('contact_us') }}</a>
         </p>
        </b-col>
        <b-col md="3">
          <a href="https://twitter.com/mokhatat"><img src="@/assets/images/icons/twitter.svg" width="30px" ></a>
          <a href="https://facebook.com/mokhatat"><img src="@/assets/images/icons/facebook.svg" width="30px" ></a>
          <a href="https://instagram.com/mokhatat.sa"><img src="@/assets/images/icons/instagram.svg" width="30px" ></a>
        </b-col>
      </b-row>
    </b-container>
  </footer>
 </div>
</template>

<script>
export default {
  name: 'MFooter',
}
</script>
