<template>
  <div>
   <navbar />
   <router-view></router-view>
   <m-footer />
 </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue"
import MFooter from "@/components/Footer.vue"

export default {
  title: "الشروط والاحكام",
  components: {
    Navbar,
    MFooter
  }
}
</script>
